<template>
  <div class="d-none d-sm-block wishlist-width">
    <referral-model
      :is-selection="true"
      :referral-email="referralEmail"
      :referral-wishlist-id="referralWishlistId"
      :wishlist="selectedWishlist"
    />
    <b-nav-item-dropdown
      id="open_my_wishlist"
      class="dropdown-cart w-100"
      menu-class="dropdown-menu-media"
      right
      @show="fetchWishlist"
    >
      <template
        v-if="currentWishlist && !currentWishlist.isReadOnly"
        #button-content
      >
        <div class="border border-light color-white p-50 rounded">
          <span class="ml-50 d-block text-truncate text-ellipsis pr-1">
            {{ currentWishlist.name }}
          </span>
          <feather-icon
            icon="ChevronDownIcon"
            class="wishlist-caret-icon"
          />
        </div>
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            My {{ isRetailer ? 'Wishlists' : 'Selections' }}
          </h4>
          <b-badge
            v-if="wishlists.length"
            pill
            variant="light-primary"
          >
            {{ wishlists.length }} items
          </b-badge>
        </div>
      </li>

      <!-- Cart Items -->
      <vue-perfect-scrollbar
        v-if="wishlists.length"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <b-media
          v-for="wishlist in wishlists"
          :key="wishlist._id"
        >
          <b-link
            class="media-heading"
            @click.stop="switchWishlist(wishlist)"
          >
            <h6 class="cart-item-title">
              <b-link class="text-body">
                {{ wishlist.name }}
              </b-link>
            </h6>
          </b-link>

          <h5 class="cart-item-price">
            <share-icon
              v-if="isBrand"
              v-b-tooltip.hover="'Share'"
              v-b-modal.stop="`modal-share-wishlist-${wishlist._id}`"
              class="mr-1"
            />
            <feather-icon
              v-b-tooltip.hover="'Download'"
              v-b-modal.stop="`modal-download-wishlist-${wishlist._id}`"
              icon="DownloadCloudIcon"
              size="20"
              class="mr-1"
            />
            <feather-icon
              v-b-tooltip.hover="'Rename'"
              v-b-modal.stop="`modal-rename-wishlist-${wishlist._id}`"
              icon="EditIcon"
              size="20"
              class="mr-1"
            />
            <feather-icon
              v-b-tooltip.hover="'Delete'"
              v-b-modal.stop="`modal-delete-wishlist-${wishlist._id}`"
              icon="TrashIcon"
              size="20"
            />
          </h5>

          <wishlist-action
            :wishlist="wishlist"
            @renamed="wishlistRenamed(wishlist)"
            @open-referral-model="openReferralModel"
            @get-current-wishlist="getCurrentWishlist"
          />
        </b-media>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import WishlistAction from '@/views/apps/wishlist/WishlistAction.vue'
import ShareIcon from '@/@core/assets/svg-components/ShareIcon.vue'
import ReferralModel from '@/views/apps/referral/referralModel.vue'
import analytics, {
  getSelectionOrWishlistMixpanelObj,
} from '@/@core/utils/analytics'
import constants from '@/constants'
import { formatObject } from '@/@core/utils/utils'
import { FETCH_WISHLISTS, UPDATE_CURRENT_CHECKOUT_WISHLIST } from '@/store/modules/shop.module'
import UserRoleMixinVue from '@/views/apps/UserRoleMixin.vue'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    WishlistAction,
    ShareIcon,
    ReferralModel,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixinVue],
  data() {
    return {
      wishlists: [],
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      referralEmail: '',
      referralWishlistId: '',
      selectedWishlist: {},
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      this.items.forEach(i => {
        total += i.price
      })
      return total
    },
    currentWishlist() {
      return this.$store.getters.getCurrentCheckoutWishlist
    },
  },
  mounted() {
    if (!this.wishlists.length) {
      this.fetchWishlist()
    }
  },
  methods: {
    async fetchWishlist() {
      await this.$store
        .dispatch(FETCH_WISHLISTS, { force: true })
        .then(wishlists => {
          this.wishlists = wishlists.filter(
              wishlist => !wishlist?.isReadOnly,
            )
        })
    },
    switchWishlist(wishlist) {
      if (this.isRetailer) {
        analytics.track(
          constants.TRACKS.PAGES.RETAILER_OPENS_WISHLISTS_BY_DROPDOWN,
          formatObject(getSelectionOrWishlistMixpanelObj(wishlist, false)),
        )
      }
      if (wishlist._id !== this.currentWishlist?._id) {
        this.$router.push({
        path: `/${this.isRetailer ? 'wishlist-products' : 'selection-products'}/${
          wishlist._id
        }`,
      })
      }
    },
    wishlistRenamed(wishlist) {
      if (wishlist._id === this.currentWishlist._id) {
        this.$store.commit(UPDATE_CURRENT_CHECKOUT_WISHLIST, wishlist)
        this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.map(
          item => {
            if (item.dynamic === true) {
              if (Object.prototype.hasOwnProperty.call(item, 'wishlistName')) {
                item.wishlistName = wishlist.name
              }
            }
            return item
          },
        )
      }
    },
    openReferralModel(email, wishlist) {
      this.selectedWishlist = wishlist
      this.referralEmail = email
      this.referralWishlistId = wishlist._id
      this.$bvModal.show('referal-model')
    },
    getCurrentWishlist(wishlist = null) {
      this.currentWishlist = wishlist
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart {
  .media-heading {
    width: 19rem;
  }
  .cart-item-price {
    width: 9rem;
    svg {
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}
.wishlist-width {
  max-width: 200px;
}
.wishlist-caret-icon {
  float: right;
  margin-top: -1.3rem;
}
</style>
